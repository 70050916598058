import React from 'react'
import NextHead from 'next/head'

interface Props {
	homeTitle?: string
	title: string
	description: string
	image: string
}

export const Head = ({ homeTitle, title, description, image }: Props): JSX.Element => {
	return (
		<NextHead>
			<title>{homeTitle || `${title} | 長町近郊の飲食店宅配サービス | ナガタク`}</title>
			<meta
				property="og:title"
				content={homeTitle || `${title} | 長町近郊の飲食店宅配サービス | ナガタク`}
			/>
			<meta property="og:description" content={description} />
			<meta property="og:type" content="website" />
			<meta property="og:url" content="https://www.nagataku.com/" />
			<meta property="og:image" content={image} />
			<meta
				property="og:site_name"
				content={homeTitle || `${title} | 長町近郊の飲食店宅配サービス | ナガタク`}
			/>
			<meta name="twitter:card" content="summary" />
			<meta name="twitter:site" content="@nagamachi_dv" />
			<meta name="twitter:creator" content="@nagamachi_dv" />
			<meta
				name="twitter:title"
				content={homeTitle || `${title} | 長町近郊の飲食店宅配サービス | ナガタク`}
			/>
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />
			<link rel="canonical" href="https://www.nagataku.com/" />
		</NextHead>
	)
}
