import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import * as Sentry from '@sentry/browser'
import { axios } from '../lib/axios-client'
import { Shop } from '../types/shop'
import { LP } from '../components/LP'
import { Head } from '../components/Head'
import { Divider } from '../components/Divider'
import { StorageImage } from '../components/StorageImage'
import { Modal } from '../components/Modal'
import { useAuth } from '../context/auth'
import { useCart } from '../context/cart'
import { FaExchangeAlt } from 'react-icons/fa'

type Props = {
	shops: Shop[]
}

const Index = ({ shops }: Props) => {
	const { isLoadingAuth } = useAuth()
	const { cart, isLoadingCart, fetchOrCreateCart } = useCart()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [error, setError] = useState('')

	const takeoutDisabled = true

	useEffect(() => {
		setError('')
	}, [isModalOpen])

	const handleChangeOrderType = async () => {
		if (!cart) {
			return
		}

		const order_type = cart.order_type === 'delivery' ? 'takeout' : 'delivery'

		const shopIds: number[] = []

		cart.line_items.forEach((lineItem) => {
			!shopIds.includes(lineItem.product.shop.id) && shopIds.push(lineItem.product.shop.id)
		})

		if (order_type === 'takeout' && shopIds.length > 1) {
			setError('複数の店舗が選択されています。テイクアウトの注文先は1店舗にしてください。')
		} else {
			try {
				await axios.patch(`${process.env.REST_ENDPOINT}/api/v1/carts/${cart.id}`, { order_type })
				setIsModalOpen(false)
				fetchOrCreateCart()
			} catch (error) {
				Sentry.captureException(error)
			}
		}
	}

	return (
		<>
			<Head
				homeTitle="【ナガタク】長町近郊の飲食店宅配サービス | 複数店舗の組み合わせ自由"
				title=""
				description="ナガタクは長町近郊の飲食店からお食事を探して注文できる宅配サービスです。和食・中華・韓国料理・カレー・イタリアン・コーヒー・ケーキ・お酒など様々なジャンルのお店全店舗自由に組み合わせて注文可能です。"
				image="https://www.nagataku.com/logo2.svg"
			/>
			{isLoadingAuth || isLoadingCart ? (
				<></>
			) : cart && cart.order_type ? (
				<>
					{/* TODO: classNameを動的にセットできるか調査 */}
					{cart.order_type === 'delivery' ? (
						<div className="bg-blue z-10 fixed px-4 py-3 text-xl text-center shadow-md w-full">
							<div className="flex items-center justify-between md:w-[1040px] md:mx-auto">
								<div className="w-16" />
								<div>デリバリー</div>
								<button onClick={() => setIsModalOpen(!isModalOpen)}>
									<FaExchangeAlt className="w-16" />
								</button>
							</div>
						</div>
					) : (
						<div className="bg-pink z-10 fixed px-4 py-3 text-xl text-center shadow-md w-full">
							<div className="flex items-center justify-between md:w-[1040px] md:mx-auto">
								<div className="w-16" />
								<div>テイクアウト</div>
								<button onClick={() => setIsModalOpen(!isModalOpen)}>
									<FaExchangeAlt className="w-16" />
								</button>
							</div>
						</div>
					)}

					<Modal isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}>
						<div className="text-center">
							{takeoutDisabled ? (
								<>
									<div className="text-md">
										テイクアウトモードは準備中です。
										<br />
										もうしばらくお待ち下さい。
									</div>
									<button
										className="shadow-md rounded-lg bg-gray text-md text-white font-bold w-28 h-12 mx-2 mt-4"
										onClick={() => setIsModalOpen(false)}
									>
										閉じる
									</button>
								</>
							) : (
								<>
									<div className="text-lg">
										{cart.order_type === 'delivery' ? 'テイクアウト' : 'デリバリー'}に変更しますか？
									</div>
									{error && <div className="text-red mt-2">{error}</div>}
									<div className="flex justify-center mt-4">
										<button
											className="shadow-md rounded-lg bg-gray text-md text-white font-bold w-36 h-12 mx-2"
											onClick={() => setIsModalOpen(false)}
										>
											キャンセル
										</button>
										<button
											className="shadow-md rounded-lg bg-orange text-md text-white font-bold w-36 h-12 mx-2"
											onClick={handleChangeOrderType}
										>
											変更
										</button>
									</div>
								</>
							)}
						</div>
					</Modal>

					<div className="pt-20 p-6 pb-28 md:flex md:justify-between md:flex-wrap md:w-[1040px] md:mx-auto">
						{shops.length > 0 &&
							shops.map((shop: Shop, index: number) => (
								<Link key={index} href={shop.status === 1 ? `/shops/${shop.id}` : '#'}>
									<div className="md:w-[320px]">
										<div
											className={`shadow-md rounded-lg ${shop.status === 1 && 'cursor-pointer'}`}
										>
											<div className="h-36 relative overflow-hidden">
												{shop.image ? (
													<StorageImage
														className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
														src={shop.image}
														alt={shop.title}
													/>
												) : (
													<img
														className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-36"
														src="/logo2.svg"
														alt={shop.title}
													/>
												)}
												{shop.status !== 1 && (
													<div className="absolute rounded-t-lg text-lg text-white font-bold bg-black bg-opacity-40 top-0 right-0 bottom-0 left-0">
														<div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
															準備中
														</div>
													</div>
												)}
											</div>
											<div className="p-2">
												<div className="font-bold">{shop.title}</div>
											</div>
										</div>
										<div className="md:my-8">
											<Divider />
										</div>
									</div>
								</Link>
							))}
					</div>
				</>
			) : (
				<LP />
			)}
		</>
	)
}

export async function getServerSideProps() {
	const res = await fetch(`${process.env.REST_ENDPOINT}/api/v1/shops`)
	const data = await res.json()

	if (!data) {
		return {
			notFound: true,
		}
	}

	return {
		props: { shops: data },
	}
}

export default Index
