import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/browser'
import { axios } from '../lib/axios-client'
import { useCart } from '../context/cart'
import { Footer } from './Footer'

export const LP = () => {
	const router = useRouter()

	const { cart, fetchOrCreateCart } = useCart()

	const handleChangeOrderType = async (orderType: string) => {
		if (!cart) {
			return
		}
		try {
			await axios.patch(`${process.env.REST_ENDPOINT}/api/v1/carts/${cart.id}`, {
				order_type: orderType,
			})
			fetchOrCreateCart()
			router.push('/')
		} catch (error) {
			Sentry.captureException(error)
		}
	}

	return (
		<>
			<div className="md:w-[1040px] mx-auto">
				<div className="p-4">
					<div className="md:flex items-center">
						<img className="w-full mb-8 block md:hidden" src="/lp@2x.jpg" alt="lp" />
						<img className="w-[775px] mb-8 hidden md:block" src="/lp_pc@2x.jpg" alt="lp" />

						<div className="flex md:block justify-between mb-8">
							<div className="p-2 w-1/2 md:w-[246px]">
								<button className="" onClick={() => handleChangeOrderType('delivery')}>
									<img src="/delivery@2x.jpg" alt="delivery" />
								</button>
							</div>

							<div className="p-2 w-1/2 md:w-[246px]">
								<button
									className="relative"
									onClick={() => handleChangeOrderType('takeout')}
									disabled
								>
									<div className="font-bold bg-white opacity-80 absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 py-1 w-24">
										準備中です
									</div>
									<img src="/takeout@2x.jpg" alt="takeout" />
								</button>
							</div>
						</div>
					</div>

					<div className="md:flex items-center">
						<img
							className="w-full md:w-1/2 px-4 mb-8"
							src="/coupon_banner@2x.jpg"
							alt="coupon_banner"
						/>

						<img className="w-full md:w-1/2 px-2 mb-8" src="/shopping@2x.jpg" alt="shopping" />
					</div>
				</div>

				<div className="p-4 mb-8">
					<div className="md:w-[686px] md:mx-auto">
						<Link href="/about">
							<div className="w-full mb-8 cursor-pointer">
								<img className="block md:hidden" src="/about@2x.jpg" alt="what_is_nagataku" />
								<img className="hidden md:block" src="/about_pc@2x.jpg" alt="what_is_nagataku" />
							</div>
						</Link>
					</div>

					<div className="md:flex justify-between md:items-end">
						<div className="w-full md:mr-8">
							<a
								href="https://en-gage.net/sun-trust_recruit/work_1622140/"
								target="_blank"
								rel="noreferrer"
							>
								<img className="md:hidden" src="/recruit@2x.jpg" alt="recruit" />
								<img className="hidden md:block" src="/recruit_pc@2x.jpg" alt="recruit" />
							</a>
						</div>
						<div className="w-full mt-8 md:w-[400px]">
							<a
								href="https://docs.google.com/forms/d/e/1FAIpQLSeqrSIrDKshei4MZTtHVHXMbowdpsWHARjlBVlilWnE9HB3WQ/viewform?usp=sf_link"
								target="_blank"
								rel="noreferrer"
							>
								<img className="md:hidden" src="/contact_sp@2x.jpg" alt="contact" />
								<img className="hidden md:block" src="/contact_pc@2x.jpg" alt="contact" />
							</a>
						</div>

						{/* <div className="w-full pr-2">
							<img className="block md:hidden" src="/sponsor@2x.jpg" alt="sponsor" />
							<img className="hidden md:block" src="/sponsor_pc@2x.jpg" alt="sponsor" />
						</div> */}
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
