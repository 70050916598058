import React, { useState, useEffect } from 'react'
import { storage } from '../lib/firebase-client'

type Props = {
	src: string
	className?: string
	alt?: string
	height?: string
	width?: string
}

export const StorageImage = (props: Props) => {
	const { src, className, alt, height, width } = props

	const [imageSrc, setImageSrc] = useState('')

	useEffect(() => {
		const fetchImageSrc = async (src: string) => {
			try {
				const ref = storage.ref(src)
				const imageSrc = await ref.getDownloadURL()
				setImageSrc(imageSrc)
			} catch (e) {
				// 画像がない場合
			}
		}

		src && fetchImageSrc(src)
	}, [src])

	return imageSrc ? (
		<img src={imageSrc} className={className} alt={alt} height={height} width={width} />
	) : (
		<></>
	)
}
